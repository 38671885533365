import React, { useRef, useState } from 'react';
import { NewsStoryProps } from './NewsStory';
import { motion, AnimatePresence } from 'framer-motion';
import { MotionImage, useSanitizedTranslation } from 'ui';
import { useRouter } from 'next/router';
import { useTimer } from 'react-timer-hook';

export type PressBlockProps = {
  heading: string;
  body?: string;
  stories: NewsStoryProps[];
};

const variants = {
  initial: (i: number) => ({
    opacity: 0,
    x: -40,
  }),
  animate: (i: number) => ({
    x: 0,
    y: 0,
    opacity: 1,
    transition: { staggerChildren: 0.075, ease: 'easeInOut', duration: 0.5 },
  }),
};

export const PressBlock: React.FC<{ title: string; date: Date; description: string; content: string; thumbnail: any; additionalImages: any[] }> = ({
  title,
  date: d,
  description,
  content,
  thumbnail,
  additionalImages = [],
}) => {
  const { t } = useSanitizedTranslation();
  const router = useRouter();
  const ref = useRef<any>();

  const date = new Date(d);

  const variants = {
    initial: {
      opacity: 0,
      y: 20,
      transition: { type: 'spring', damping: 65, stiffness: 900, mass: 1 },
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: { staggerChildren: 0.1, type: 'spring', damping: 65, stiffness: 900, mass: 1 },
    },
  };

  const carouselMode = !!additionalImages.length;
  const carouselImages = [thumbnail, ...additionalImages];

  const [slide, setSlide] = useState(0);

  const fiveSeconds = () => {
    return new Date(new Date().getTime() + 5000);
  };
  const time = new Date();
  const { totalSeconds, seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useTimer({
    expiryTimestamp: fiveSeconds(),
    autoStart: carouselMode,
    onExpire: () => {
      updateSlide();
    },
  });

  const updateSlide = slideNum => {
    setSlide(slide => (typeof slideNum !== 'undefined' ? slideNum : (slide + 1) % carouselImages.length));
    if (typeof slideNum === 'undefined') {
      setTimeout(() => restart(fiveSeconds(), true), 5);
    } else {
      pause();
    }
  };

  return (
    <motion.section ref={ref} initial="initial" animate="animate" variants={variants} className="wrapper">
      <div className="content-wrapper relative mb-12 flex w-full flex-col items-start justify-center py-6 lg:flex-row">
        <motion.div variants={variants} className={`mb-4 text-left lg:sticky lg:top-[110px] lg:basis-2/5 lg:pr-16 xl:basis-1/3`}>
          <h1 className={`type-heading-alt mb-8 text-xumoTeal`} dangerouslySetInnerHTML={{ __html: title }} />
          <time dateTime={date.toISOString()} className="type-body weight-500">
            {date.toLocaleDateString(router.locale, { month: 'long', day: 'numeric', year: 'numeric' })}
          </time>
        </motion.div>
        <div className={`lg:basis-3/5 xl:basis-2/3`}>
          <motion.div variants={variants} className={`type-body text-left text-md lg:text-xl`} dangerouslySetInnerHTML={{ __html: description }} />
          {!carouselMode && (
            <motion.div className={`relative my-6 w-full overflow-hidden rounded-lg`} style={{ aspectRatio: thumbnail.width / thumbnail.height }}>
              <MotionImage
                variants={variants}
                src={thumbnail.src}
                alt={thumbnail.alt ?? ''}
                fill
                className="object-contain object-left"
                sizes={'(max-width: 768px) 100vw, (max-width: 1600px) 60vw, 40vw'}
                priority
              />
            </motion.div>
          )}
          {carouselMode && (
            <>
              <div className="relative mt-8 aspect-video">
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0, zIndex: 5 }}
                    animate={{ opacity: 1, zIndex: 4 }}
                    exit={{ opacity: 0, zIndex: 3, transition: { delay: 2 } }}
                    key={'custom' + slide}
                    className={`absolute left-0 top-0  aspect-video w-full overflow-hidden rounded-lg`}
                  >
                    <MotionImage
                      src={carouselImages[slide].src}
                      alt={carouselImages[slide].alt ?? ''}
                      fill
                      className="object-contain object-left"
                      sizes={'(max-width: 768px) 100vw, (max-width: 1600px) 75vw, 55vw'}
                      priority
                    />
                  </motion.div>
                </AnimatePresence>
              </div>

              <div className="my-2 flex items-center justify-center pb-8">
                {carouselImages.map((_, id) => (
                  <button
                    key={'slide-control' + id}
                    aria-label={`Slide ${id + 1}`}
                    aria-current={slide === id ? 'true' : 'false'}
                    className={`${
                      slide === id ? 'w-12' : 'w-3'
                    } relative m-1 inline-block h-3 rounded-full bg-black opacity-60 transition-all hover:bg-[#BB2E76] hover:opacity-100`}
                    onClick={() => updateSlide(id)}
                  />
                ))}

                <button
                  onClick={() => (isRunning ? pause() : resume())}
                  aria-label={isRunning ? 'Pause' : 'Play'}
                  aria-pressed={isRunning}
                  className="ml-8 flex h-4 w-4 items-center justify-center focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                  {isRunning ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6f h-6 fill-black opacity-60 transition-all hover:fill-[#BB2E76] hover:opacity-100"
                      viewBox="0 0 448 512"
                      aria-hidden="true"
                    >
                      <path d="M144 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h96c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm256 0h-96c-26.51 0-48 21.49-48 48v352c0 26.51 21.49 48 48 48h96c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 fill-black opacity-60 transition-all hover:fill-[#BB2E76] hover:opacity-100"
                      viewBox="0 0 448 512"
                      fill="#BB2E76"
                      aria-hidden="true"
                    >
                      <path d="M424.41 214.66L72.41 3.66C51.82-7.72 24 5.52 24 32v448c0 26.48 27.82 39.72 48.41 28.34l352-211c22.49-13.49 22.49-43.19 0-56.68z" />
                    </svg>
                  )}
                </button>
              </div>
            </>
          )}
          <div aria-hidden="true" style={{ position: 'absolute', left: -9999, top: -9999 }}>
            {carouselImages.map((image, index) => (
              <MotionImage key={index} src={image.src} alt={''} fill priority={index < 2} sizes={'(max-width: 768px) 100vw, (max-width: 1600px) 75vw, 55vw'} />
            ))}
          </div>
          <motion.div variants={variants} className={`type-body allow-html text-left`} dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </motion.section>
  );
};
