/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion, AnimatePresence, useTransform } from 'framer-motion';

import { useState, useEffect } from 'react';
import { MotionImage, useSanitizedTranslation, useInViewScroll, StackedContent } from 'ui';

const AudioBars = ({ scrollY }) => {
  const barValues = [
    0.05, // Start with low values (low frequency amplitudes)
    0.1,
    0.2,
    0.4,
    0.6,
    0.85, // Peak values (mid-high frequency amplitudes)
    1,
    0.85, // Mirroring the drop-off on the other side of the curve
    0.6,
    0.4,
    0.3, // Smoother decrease (lower-mid frequencies)
    0.2,
    0.1,
    0.05, // Return to lower values for low frequencies
    0.05,
    0.1,
    0.2,
    0.5,
    0.75,
    0.9, // Another small peak for variation
    1, // Max amplitude (high frequency)
    0.9, // Decrease back down
    0.75,
    0.5,
    0.3,
    0.2, // Smoothing out the tail of the wave
    0.1,
    0.05,
    0.05, // End with the lowest amplitudes
  ];

  const imageY = useTransform(scrollY, [0, 1], [-25, 50]);

  const totalBars = barValues.length;
  const barGap = 80 / (totalBars * 2);
  const barWidth = (100 - barGap * (totalBars - 1)) / totalBars;
  const maxHeight = 30;
  const rectRadius = 2;

  // Create keyframes that pulse from .1 to maxHeight, 3 times rapidly, then pause
  const generateKeyframes = value => {
    return [
      maxHeight * 0.11, // Start from 10% height
      maxHeight * (0.11 + (value - 0.3) + Math.random() * 0.3), // Pulse to max height
      maxHeight * (0.11 + (value * 0.5 - 0.1) + Math.random() * 0.1),
      maxHeight * (0.11 + (value - 0.3) + Math.random() * 0.3), // Pulse to max again
      maxHeight * (0.11 + (value * 0.5 - 0.1) + Math.random() * 0.1),
      maxHeight * (0.11 + (value - 0.3) + Math.random() * 0.3), // Final pulse to max
      maxHeight * (0.11 + (value * 0.5 - 0.1) + Math.random() * 0.1),
      maxHeight * (0.11 + (value - 0.3) + Math.random() * 0.3), // Final pulse to max
      maxHeight * 0.11,
      maxHeight * 0.11,
    ];
  };

  const sineWaveY = index => {
    const amplitude = 2.3;
    const period = 2 * Math.PI;
    return {
      y: Array(101)
        .fill(0)
        .map((_, i) => amplitude * Math.sin((i / 100) * period + index * 0.5)), // Create sine wave motion
      transition: {
        repeat: Infinity,
        repeatType: 'loop',
        duration: 10, // Slight variation in duration per bar
      },
    };
  };

  return (
    <>
      <svg className="absolute z-0 flex h-full w-full" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
        <defs>
          <mask id="audio-mask">
            {barValues.map((value, index) => {
              const keyframes = generateKeyframes(value);
              return (
                <motion.g
                  key={index}
                  animate={sineWaveY(index)}
                  style={{
                    opacity: index === 0 || index === barValues.length - 1 ? 0.25 : index === 1 || index === barValues.length - 2 ? 0.5 : 1,
                  }}
                >
                  <motion.rect
                    x={index * (barWidth + barGap)}
                    y={(100 - maxHeight) / 2}
                    width={barWidth}
                    height={0.1 * maxHeight}
                    rx={rectRadius}
                    ry={rectRadius}
                    fill="white"
                    animate={{
                      y: keyframes.map(val => (maxHeight - Math.max(0.05 * maxHeight, val)) / 2),
                      height: keyframes.map(val => Math.max(0.05 * maxHeight, val)),
                      opacity: [0.35, 0.75, 0.75, 0.75, 0.75, 0.75, 0.75, 0.75, 0.75, 0.35],
                    }}
                    transition={{
                      repeat: Infinity,
                      duration: 4.5,
                      delay: 3.75,
                      times: [0, 0.03, 0.05, 0.06, 0.08, 0.1, 0.11, 0.14, 0.16, 1],
                    }}
                  />
                </motion.g>
              );
            })}
          </mask>
        </defs>
        <g mask="url(#audio-mask)">
          <motion.image style={{ y: imageY }} href="/static/images/blue-swirl-bg.png" width="100" height="100" preserveAspectRatio="xMidYMid slice" />
        </g>
      </svg>
    </>
  );
};

export const RemoteBlock = ({ content, tvRemote, bg = '' }) => {
  const commands = ['"What should I watch?"', '“Watch Monday Night Football”', '“Open Xumo Play”', '“Watch NBC”', '“Show me free movies”'];

  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const scrollY = useTransform(amt, [0, 1], [-25, 75]);
  const barsY = useTransform(amt, [0, 1], [-25, 25]);

  const [commandState, setCommandState] = useState(0);

  const nextState = () => {
    setCommandState(state => (state + 1) % commands.length);
  };

  useEffect(() => {
    const intervalId = setInterval(nextState, 4500);
    return () => clearInterval(intervalId);
  }, []);

  const { t } = useSanitizedTranslation();
  return (
    <motion.section className="justify-centent relative z-40 flex w-full items-center bg-white" data-testid={'remote-section'}>
      <div className="wrapper">
        <div className={`content-wrapper flex flex-col items-center justify-center pt-16`}>
          <StackedContent
            full
            center
            noOrphans
            content={[
              { heading: 'Say the word' },
              {
                body: 'Our voice remote features award-winning voice technology so you can find your next watch faster, navigate easier and stream even better.',
              },
            ]}
          />
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="relative -mb-4 flex w-full flex-col items-center justify-center overflow-clip">
            <motion.div className="weight-300 relative mb-16 text-[20px] tracking-tight md:text-[32px] lg:text-[40px]">
              <span className="invisible">&nbsp;</span>
              <AnimatePresence>
                <motion.div
                  className="absolute left-0 flex w-full justify-center"
                  key={'presence' + commandState}
                  initial={{ opacity: 0, y: 10, scale: 1.1, color: '#3ecffc' }}
                  animate={{ opacity: 1, y: 0, color: '#000' }}
                  exit={{ opacity: 0, y: -10, scale: 0.9 }}
                  transition={{ duration: 0.4, ease: 'easeInOut' }}
                >
                  {commands[commandState].split(' ').map((word, index) => (
                    <motion.span
                      className="inline-block"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: { ease: [0, 1, 1, 1], duration: 1.0, delay: index * 0.15, deltaRest: 0.00001, deltaSpeed: 0.00001 },
                      }}
                      key={'word' + word}
                      dangerouslySetInnerHTML={{ __html: word + '&nbsp;' }}
                    />
                  ))}
                </motion.div>
              </AnimatePresence>
            </motion.div>

            <motion.div style={{ y: barsY }} className="absolute left-0 top-[25%] z-0 h-[80%] w-full md:left-[13.5%] md:w-3/4  xl:left-1/4 xl:w-1/2">
              <AudioBars scrollY={amt} />
            </motion.div>
            <motion.div ref={ref} style={{ y: scrollY }} className="relative -mt-2 h-[300px] w-[300px] md:mt-4 lg:h-[425px] lg:w-[425px]">
              <MotionImage
                src={tvRemote ? '/static/images/refresh/tv-remote.png' : '/static/images/refresh/remote.png'}
                priority
                fill
                alt=""
                sizes="100vw"
                className="h-full w-full object-contain"
              />
            </motion.div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};
