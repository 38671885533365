import { DirectusNewsBlock } from 'config';
import { NewsBlockProps } from '../../../components/NewsBlock';
import { mapDirectusImage, mapDirectusCTAs } from '../general';
import websiteContent from 'config/xumo-website-output.json';

export const mapDirectusNewsBlock = (gallery: DirectusNewsBlock): NewsBlockProps => {
  const stories = [...websiteContent.press, ...websiteContent.links]
    .sort((a, b) => {
      const dateA = a.date_posted || a.date;
      const dateB = b.date_posted || b.date;

      return new Date(dateA) > new Date(dateB) ? -1 : 1;
    })
    .filter(story => {
      if (!gallery.tags?.length) return true;
      return story.tags?.some(item => new Set(gallery.tags || []).has(item));
    })
    .slice(0, gallery.max_items)
    .map(news => ({
      ...news,
      date: news.date_posted || news.date,
      thumbnail: mapDirectusImage(news.thumbnail),
    }));

  console.log('LET ALL STORIES?', !gallery.tags?.length);
  console.log('NUM STORIES', [...websiteContent.press, ...websiteContent.links].length);
  console.log(
    'HERE ARE TEH STORIES',
    [...websiteContent.press, ...websiteContent.links]
      .sort((a, b) => (new Date(a.date) > new Date(b.date) ? -1 : 1))
      .map(story => story.title + ' ' + story.date)
      .join('\n'),
  );
  console.log('FOR THIS BLOCK', stories.map(JSON.stringify));

  return {
    heading: gallery.heading,
    body: gallery.body,
    cta: gallery.cta?.length ? mapDirectusCTAs(gallery.cta, null, true) : undefined,
    tags: gallery.tags,
    stories,
  };
};

export const mapDirectusPressBlock = (press): NewsBlockProps => ({
  title: press.title,
  date: press.date_posted || press.date,
  description: press.description,
  content: press.content,
  tags: press.tags,
  thumbnail: mapDirectusImage(press.thumbnail),
  additionalImages: (press as any)?.additional_images?.map((image: any) => mapDirectusImage(image.directus_files_id)),
});
