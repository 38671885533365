'use client';

/* eslint-disable max-len */
import { motion, useSpring, useTransform, AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useInViewScroll, useSanitizedTranslation, useWindowSize } from '../hooks';
import { makeVariants } from '../utils/makeVariants';
import { MotionImage } from './AnimatedImage';
import { CopyProps, CopySection } from './CopySection';
import { useScrolledTheme, GlobeBlock, StatsBlock, TechStackBlock } from 'ui';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';

import { CTA, Colors, IImage } from '../types';

export type TvSplashBlockProps = {
  copyProps: CopyProps;
  backgroundColor: string;
  imageLayout: { top: Layout; bottom: Layout };
  topSwirl: IImage;
  bottomSwirl?: IImage;
  deviceImages?: IImage[];
  devicePlacement?: 'left-overlap' | 'left' | 'right-overlap' | 'right';
  ctas: CTA[];
  headingOne?: boolean;
  fineprint?: string;
  imageFineprint?: string;
};

type Layout = 'full-screen' | 'right' | 'right-wide';

const squiggleVariant = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
    },
  },
};

// TODO: Add accents to TvSplash
const colorFallback = {
  '/shop': '#396059',
  '/shop/xumo-tv': '#0029FF',
  '/shop/xumo-stream-box': '#0029FF',
  '/shop/streaming': '#0029FF',
};

const retailFg = '/static/images/blue-ribbon.png';
const retailSwirl = '/static/images/blue-ribbon.png';

const streamingFg = '/static/images/xumo-stream-box-product.png';
const streamingSwirl = '/static/images/blue-ribbon.png';

// TODO: add to Directus
const video = 'https://xumo-marketing.directus.app/assets/8630252f-4f56-4c2f-b922-342de8c54a91';

export const TvSplashBlock: React.FC<TvSplashBlockProps> = ({
  copyProps,
  backgroundColor,
  topSwirl,
  bottomSwirl,
  deviceImages: imgs,
  devicePlacement,
  imageLayout,
  headingOne,
  fineprint,
  imageFineprint,
  ctas,
}) => {
  const { width } = useWindowSize();
  const { ref, progress: y } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const containerY = useTransform(y, [0, 1], [-100, 100]);

  const router = useRouter();

  const flipped = copyProps.heading.indexOf('Retail') >= 0;
  const hiddenButton = copyProps.heading.indexOf('Built by') >= 0;

  const fallbackSwirl = flipped ? retailSwirl : hiddenButton ? streamingSwirl : topSwirl.src;

  const fallbackColor = colorFallback[router.asPath as keyof typeof colorFallback];

  const deviceImages = flipped ? imgs : hiddenButton ? [{ src: streamingFg }] : imgs;

  const variants = makeVariants.slideIn();
  const imgVariants = {
    initial: {
      opacity: 0,
      rotateY: '-50deg',
      x: 30, //devicePlacement === 'right-overlap' ? 30 : devicePlacement === 'left-overlap' ? -30 : 0,
    },
    animate: (i: number) => ({
      opacity: 1,
      rotateY: '-10deg',
      x: 0,
      transition: {
        type: 'spring',
        delay: 0.4 * i,
      },
    }),
  };

  const videoVariants = {
    initial: {
      opacity: 0,
      rotateY: '-60deg',
      x: 30, //devicePlacement === 'right-overlap' ? 30 : devicePlacement === 'left-overlap' ? -30 : 0,
    },
    animate: (i: number) => ({
      opacity: 1,
      rotateY: '-10deg',
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 80,
        damping: 30,
      },
    }),
  };

  const videoRef = useRef();

  const [videoShown, showVideo] = useState(false);

  useEffect(() => {
    if (videoShown && videoRef.current) {
      (videoRef.current as any).play();
    }
  }, [videoShown]);

  const tvHero = router.asPath.indexOf('/products/xumo-tv') === 0 && headingOne;
  const enterprise = router.asPath.indexOf('/enterprise') === 0 && router.asPath.indexOf('/enterprise/case') !== 0;
  const enterpriseHero = enterprise && headingOne;

  const isAdvertising = router.asPath.indexOf('/advertising') === 0;

  const adStyles = isAdvertising ? { style: { background: '#f5f7f8', borderRadius: 30 } } : { style: { background: '#f5f7f8', borderRadius: 30 } };

  return (
    <>
      <section ref={ref} className={`wrapper relative z-10 flex w-full flex-col py-4 md:flex-row md:py-8`} data-id={'tv-splash-block'}>
        <motion.div
          variants={variants}
          initial="initial"
          whileInView={'animate'}
          viewport={{ once: true }}
          className={`content-wrapper relative z-[2] flex flex-col items-center justify-center ${
            flipped ? 'md:flex-row-reverse' : 'md:flex-row'
          } overflow-hidden`}
          style={{ color: '#000' }}
          {...adStyles}
        >
          <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 h-[115vh] overflow-hidden">
            <motion.div style={{ y: containerY }} className="container relative mx-auto h-full px-10 max-md:opacity-10">
              <AnimatePresence>
                {fallbackSwirl && true && (
                  <MotionImage
                    key="squiggle"
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    variants={squiggleVariant}
                    src={fallbackSwirl}
                    alt=""
                    quality={85}
                    sizes="(max-width: 1600px) 100vw, 75vw"
                    fill
                    className={`relative left-[-10%] h-full  w-full object-cover object-left md:left-[50%] lg:w-auto`}
                    style={{
                      scaleX: flipped ? -1 : 1,
                      transformOrigin: 'left',
                    }}
                  />
                )}
              </AnimatePresence>
            </motion.div>
          </div>
          <div className=" w-full text-left lg:w-2/5">
            <CopySection headingOne={headingOne && !tvHero} showRatings={tvHero} inheritColors {...copyProps} ctas={ctas} config={{ ...copyProps.config }} />
            {hiddenButton && (
              <Link href="#stream-box" legacyBehavior passHref>
                <motion.a whileHover="grow" className={`mt-4 inline-flex flex-row items-center`}>
                  <motion.div variants={{ grow: { scale: 1.1 } }} className="relative z-10 h-[40px] w-[40px]">
                    <Image alt="Play button" src="/static/icons/play_glow.svg" width={40} height={40} />
                  </motion.div>
                  <motion.span variants={{ grow: { opacity: 0.7 } }} style={{ color: '#000' }} className="weight-600 ml-2 text-left">
                    See Xumo Stream
                    <br />
                    Box in Action
                  </motion.span>
                </motion.a>
              </Link>
            )}
            {fineprint && <div className="type-body mt-8 text-[12px]" data-i18n={fineprint} dangerouslySetInnerHTML={{ __html: fineprint }} />}
          </div>
          <div className="relative mt-4 flex h-[250px] w-full items-center justify-center md:h-[300px] lg:mt-0 lg:h-[400px] lg:w-3/5 xl:h-[500px]">
            {(copyProps.heading || '').indexOf('Reaching streamers') > 0 && (
              <motion.div className="absolute -top-8 left-0 h-full w-full">
                <MotionImage
                  className={`absolute -top-16 z-0 h-[400px] w-full object-cover max-lg:hidden md:h-[600px] lg:h-[750px]`}
                  sizes="(max-width: 768px) 100vw, (max-width: 1600px) 60vw, 50vw"
                  quality={90}
                  src={'/static/images/blue-ribbon-2.png'}
                  style={{ y: containerY }}
                  alt={''}
                  priority
                  fill
                />
              </motion.div>
            )}
            <AnimatePresence>
              {!!deviceImages?.length &&
                [deviceImages[0]].map((image, index) => (
                  <motion.div
                    custom={index}
                    variants={imgVariants}
                    initial="initial"
                    animate="animate"
                    exit="initial"
                    viewport={{ margin: '-50% 0px -50% 0px' }}
                    className={`${index === 0 ? 'max-md:mt-12 ' : ''} ${fineprint && '-mt-6'} absolute z-[10] h-[100%] w-[90%] ${
                      flipped ? 'md:right-[10%]' : 'md:left-[10%]'
                    } max-md:mb-8`}
                    style={{ ...((copyProps.heading || '').indexOf('Reaching streamers') > 0 ? { overflow: 'hidden', borderRadius: 25 } : {}) }}
                    key={image.src || index}
                  >
                    <MotionImage
                      className={`z-10 w-full object-contain max-sm:h-full`}
                      sizes="(max-width: 768px) 100vw, (max-width: 1600px) 60vw, 50vw"
                      quality={80}
                      src={image.src}
                      alt={image.alt ?? ''}
                      priority
                      fill
                    />
                    {imageFineprint && (
                      <div
                        className="type-body absolute -bottom-8 w-full text-center text-[12px] lg:-bottom-6"
                        data-i18n={imageFineprint}
                        dangerouslySetInnerHTML={{ __html: imageFineprint }}
                      />
                    )}
                  </motion.div>
                ))}
            </AnimatePresence>
          </div>
        </motion.div>
      </section>
    </>
  );
};
