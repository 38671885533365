/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion, useSpring, useTransform, useInView, AnimatePresence } from 'framer-motion';

import { useRef, useState, useEffect } from 'react';
import { MotionImage, useSanitizedTranslation, XumoButton as Button, StackedContent } from 'ui';
import Link from 'next/link';

const TileSection = ({ subheading, tiles }) => {
  const { t } = useSanitizedTranslation();

  const transition = { ease: 'easeInOut', duration: 0.3, restDelta: 0, restSpeed: 0 };
  const variants = {
    image: { hover: { opacity: 0, scale: 1.04 } },
    hoverImage: { hover: { opacity: 1, scale: 1.04 } },
  };

  const wrapperStyles = tiles.length === 4 ? 'lg:w-2/3' : '';

  return (
    <>
      <div className="type-subheading-small mt-8 text-center" dangerouslySetInnerHTML={{ __html: subheading }} />
      <div className={'relative my-8 flex w-full flex-wrap justify-center gap-2 md:gap-4 ' + wrapperStyles}>
        {tiles.map(tile => (
          <motion.div
            key={tile.image.src}
            style={{ aspectRatio: 1145 / 600 }}
            whileHover="hover"
            className="relative flex basis-[calc(50%-8px)] cursor-pointer before:absolute before:block before:h-full before:w-full before:opacity-0 before:shadow-xl before:transition-opacity before:hover:opacity-100 md:basis-[calc(50%-16px)] lg:basis-[calc(33.33%-16px)]"
          >
            <Link
              href={tile.href}
              aria-label={tile.label}
              target="_blank"
              className="relative block aspect-[1145/600] h-full w-full overflow-clip rounded-md outline outline-2 outline-offset-2 outline-transparent transition-colors hover:outline-xumoTeal"
            >
              <MotionImage
                src={tile.image.src}
                alt=""
                initial={{ opacity: 1, scale: 1 }}
                transition={transition}
                variants={variants.image}
                priority
                fill
                sizes="(min-width: 1280px) 50vw, 100vw"
                className="h-full w-full object-cover will-change-transform"
              />
              <MotionImage
                alt=""
                src={tile.hover_image.src}
                initial={{ opacity: 0, scale: 1 }}
                transition={transition}
                variants={variants.hoverImage}
                fill
                sizes="(min-width: 1280px) 50vw, 100vw"
                className="h-full w-full object-cover will-change-transform"
              />
            </Link>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export const CtaTileBlock = ({ heading, body, sections, buttons }) => {
  const { t } = useSanitizedTranslation();

  const bg = '';
  const content = 'content';

  return (
    <motion.section className="relative z-40 flex w-full justify-center bg-white" data-testid={'text-section'}>
      <div className="wrapper">
        <div className={`content-wrapper-x flex flex-col items-center justify-center ${bg} py-16`}>
          <StackedContent center noOrphans content={[{ heading }, { body }]} />

          {sections.map(section => (
            <TileSection {...section} />
          ))}

          {
            // TODO: ButtonRow
          }
          <div className="mt-4 flex justify-center">
            {buttons.map(button => (
              <Button {...button} />
            ))}
          </div>
        </div>
      </div>
    </motion.section>
  );
};
