import Head from 'next/head';
import Script from 'next/script';
import { memo } from 'react';

export const BazaarVoiceBlock = memo(({ productId = '001' }) => {
  const isProduction = true; // process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
  const url = isProduction
    ? 'https://apps.bazaarvoice.com/deployments/xumo/main_site/production/en_US/bv.js'
    : 'https://apps.bazaarvoice.com/deployments/xumo/main_site/production/en_US/bv.js';

  return (
    <>
      <Script type="text/javascript" src={url} strategy="afterInteractive" />
      <div className="wrapper relative flex w-full pb-10">
        <div className="content-wrapper type-body text-left">
          <div className="w-full">
            <div data-bv-show="reviews" data-bv-product-id={productId}></div>
          </div>
        </div>
      </div>
    </>
  );
});
