/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useSanitizedTranslation } from 'ui';
import { useMeasure } from '@uidotdev/usehooks';

const FAQItem = ({ item, setOpen, index, isOpen }) => {
  const { t } = useSanitizedTranslation();
  const [contentRef, { height: contentHeight }] = useMeasure();

  const toggleOpen = evt => {
    setOpen(val => (index === val ? -1 : index));
  };

  const motionProps = isOpen
    ? {
        display: 'block',
        height: contentHeight,
      }
    : {
        height: 0,
        transitionEnd: {
          display: 'none',
        },
      };

  return (
    <div className="type-body w-full border-b border-black">
      <button
        aria-label={isOpen ? 'Collapse' : 'Expand'}
        id={'faq' + index}
        className="weight-500 flex w-full justify-between py-8 text-left text-[20px]"
        onClick={toggleOpen}
      >
        <div>{t(item.question)}</div>
        <div className="-mr-2 p-2">
          <img
            src="/static/icons/chevron.svg"
            className="transition-transform"
            alt=""
            style={{ width: 20, height: 20, transform: isOpen ? 'scaleY(1)' : 'scaleY(-1)' }}
          />
        </div>
      </button>
      <motion.div className="relative overflow-clip" animate={motionProps}>
        <div ref={contentRef} className="allow-html pb-8 text-left" dangerouslySetInnerHTML={{ __html: item.answer }} />
        <div className="absolute bottom-0 left-0 right-0 h-8" style={{ background: 'linear-gradient(to bottom, #fff0, #ffff)' }}></div>
      </motion.div>
    </div>
  );
};

export const FaqBlock = ({ heading, bg, questions }) => {
  const [open, setOpen] = useState(-1);
  //const faq = faqsXumoTV;

  const { t } = useSanitizedTranslation();
  return (
    <motion.section className="justify-centent relative z-40 flex w-full items-center bg-white" data-testid={'text-section'}>
      <div className="wrapper">
        <div className={`content-wrapper flex flex-col items-center justify-center ${bg}`}>
          <div className="mb-8 w-3/4 text-center">
            <h2 class="type-heading">{heading}</h2>
          </div>
          <div className="w-full max-w-[900px]">
            {questions.map((item, index) => (
              <FAQItem key={'faq-item-' + index} item={item} setOpen={setOpen} index={index} isOpen={open === index} />
            ))}
          </div>
        </div>
      </div>
    </motion.section>
  );
};
